import { SketchProvider as SketchState } from '@connect-technology/connect-react-components-lib';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/styles/App.scss';
import AppState from './context/Context';
import ScreensRoot from './screens/Root';
import TagManager from 'react-gtm-module';
import './assets/styles/App.scss';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_TAG_MANAGER_ID ?? '',
};

TagManager.initialize(tagManagerArgs);

const App = (): JSX.Element => {
	useEffect(() => {
		// Código de Google Tag Manager
		const script = document.createElement('script');
		script.innerHTML = `
		  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		  })(window,document,'script','dataLayer','GTM-MXVM96HL');
		`;
		document.head.appendChild(script);

		return () => {
			// Cleanup si es necesario
			document.head.removeChild(script);
		};
	}, []);

	return (
		<div className="app">
			<AppState>
				<SketchState>
					<Router>
						<ScreensRoot />
					</Router>
				</SketchState>
			</AppState>
		</div>
	);
};

export default App;
