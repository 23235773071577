import { getDamageArea, getDamageAreaAsNumber } from '../components/vehicle/Utils';
import { AppContext, DamageAreas, Responsible, Brands, Photo, LevelConfig, Option, CountryCode } from './interfaces';
import { DEFAULT_COORDS } from './Context';
import ConnectServicesClient from './ConnectServices';
import { SurveyPayload } from 'types/survey';

const imgsPath =
	process.env.REACT_APP_BRAND === Brands.COMFENALCO
		? `/${Brands.CONNECT}/imgs`
		: `/${process.env.REACT_APP_BRAND}/imgs`;

console.warn(imgsPath);
const commonPath = `/common/imgs`;
const BRAND = process.env.REACT_APP_BRAND || '';

// App images
export const backBtn = `${imgsPath}/back.svg`;
export const camIcon = `${imgsPath}/icon_cam.png`;
export const registroUnico = `${imgsPath}/registro_unico.svg`;
export const checkmark = `${imgsPath}/checkmark.svg`;
export const checkmarkOverview = `${imgsPath}/checkmark_overview.png`;
export const cancelIcon = `${imgsPath}/cancel.svg`;
export const closeBtn = `${imgsPath}/close.png`;
export const vehicleDiagram = `${imgsPath}/vehicle_diagram.png`;
export const carBack = `${imgsPath}/car_back.svg`;
export const carDriverSide = `${imgsPath}/car_driver_side.svg`;
export const carFront = `${imgsPath}/car_front.svg`;
export const carPassengerSide = `${imgsPath}/car_passenger_side.svg`;
export const damage = `${imgsPath}/damage.svg`;
export const fud = `${imgsPath}/fud.svg`;
export const license = `${imgsPath}/car_license.svg`;
export const cedula = `${imgsPath}/cedula.svg`;
export const odometer = `${imgsPath}/odometer.svg`;
export const fixedObject = `${imgsPath}/objeto-fijo.svg`;
export const crash = `${imgsPath}/crash.jpg`;
export const panoramic = `${imgsPath}/panoramic.svg`;
export const closeUp = `${imgsPath}/panoramic_accident.svg`;
export const plate = `${imgsPath}/car_plate.svg`;
export const addIcon = `${imgsPath}/plus.svg`;
export const other = `${commonPath}/other_photo.svg`;
export const vehicleRegistration = `${imgsPath}/ruc.svg`;
export const label = `${imgsPath}/label.svg`;
export const policy = `${imgsPath}/policy.svg`;
export const vin = `${imgsPath}/vin.svg`;
export const whatsAppLogo = `${imgsPath}/wa.svg`;
export const WALogoBlack = `${imgsPath}/whatsapp.svg`;
export const markerIcon = `${imgsPath}/marker.png`;
export const pin = `${imgsPath}/pin.png`;
export const searchIcon = `${imgsPath}/search.png`;
export const cancelCircleIcon = `${imgsPath}/cancel_icon.svg`;
export const editIcon = `${imgsPath}/edit_icon.svg`;
export const rotateIcon = `${imgsPath}/rotate_icon.png`;
export const carA = `${imgsPath}/car_a.png`;
export const carB = `${imgsPath}/car_b.png`;
export const carAHorizontal = `${imgsPath}/car_1_h.png`;
export const carBHorizontal = `${imgsPath}/car_2_h.png`;
export const car = `${imgsPath}/car.png`;
export const line = `${imgsPath}/line.png`;
export const lines = `${imgsPath}/lines.png`;
export const stopSign = `${imgsPath}/stop_sign.svg`;
export const stopSignSmall = `${imgsPath}/stop_sign_sm.svg`;
export const trafficLights = `${imgsPath}/traffic_lights.svg`;
export const trafficLightsSmall = `${imgsPath}/traffic_lights_sm.svg`;
export const arrow = `${imgsPath}/arrow.png`;
export const collision = `${imgsPath}/crash.png`;
export const dragGesture = `${imgsPath}/drag.gif`;
export const rotateGesture = `${imgsPath}/rotate.gif`;
export const rotateGesture2 = `${imgsPath}/rotateMessageInfo.png`;
export const touchGesture = `${imgsPath}/touch.gif`;
export const trashBin = `${imgsPath}/trash_bin_bg.svg`;
export const zoomGesture = `${imgsPath}/zoom.gif`;
export const colilla = `${imgsPath}/colilla.svg`;
export const deleteIcon = `${imgsPath}/trash_bin.svg`;
export const logo =
	`${imgsPath}/` +
	(imgsPath.indexOf('lafise') > -1
		? 'logo_color.jpg'
		: imgsPath.indexOf('csm') > -1 ||
		  imgsPath.indexOf('oceanica') > -1 ||
		  imgsPath.indexOf('multinational') > -1 ||
		  imgsPath.indexOf('premier') > -1
		? 'logo_color.png'
		: 'logo_color.svg');
export const pinIcon = `${imgsPath}/pin_icon.svg`;
export const warningIcon = `${imgsPath}/warning-alert.png`;
export const forwardIcon = `${imgsPath}/icon-forward.png`;
export const rightChevron = `${imgsPath}/right-chevron.svg`;
export const photoIcon = `${imgsPath}/iconPhotoMsg.png`;
export const rotateSketchIcon = `${imgsPath}/rotate-sketch-icon.png`;
export const drawIcon = `${imgsPath}/draw.svg`;
export const eraserIcon = `${imgsPath}/eraser.svg`;
export const internalPhoto = `${imgsPath}/internal_photo.svg`;
export const internalPhoto2 = `${imgsPath}/internal-photo2.png`;
export const driverLicense = `${imgsPath}/driver_license.svg`;
export const plusIcon = `${imgsPath}/plus-icon.png`;
export const expressService = `${imgsPath}/csm-express.png`;
export const headerLogo = `${imgsPath}/header-logo.png`;
export const permissionIcon = `${commonPath}/camera_permission.svg`;

export const getCurrentCountry = (): string => {
	switch (BRAND) {
		case Brands.SURA:
			return CountryCode.PAN;
		case Brands.CSM:
			return CountryCode.PRI;
		case Brands.SEGUROS_MUNDIAL:
		case Brands.COMFENALCO:
			return CountryCode.COL;
		case Brands.MULTINATIONAL:
			return CountryCode.PRI;
		case Brands.PREMIER:
			return CountryCode.PRI;
		default:
			return CountryCode.CRC;
	}
};

export const getCurrentLocation = async () => {
	const config: LevelConfig = {
		labelLevel1: 'Provincia',
		labelLevel2: 'Canton',
		labelLevel3: 'Distrito',
		levels: '',
	};
	switch (BRAND) {
		case Brands.SURA:
			config.labelLevel2 = 'Distrito';
			config.labelLevel3 = 'Corregimiento';
			break;
		case Brands.SEGUROS_MUNDIAL:
		case Brands.COMFENALCO:
			config.labelLevel1 = 'Departamento';
			config.labelLevel2 = 'Municipio';
			config.labelLevel3 = 'Corregimiento';
			break;
		case Brands.CSM:
			config.labelLevel1 = 'Municipio';
			config.labelLevel2 = 'Barrio';
			config.labelLevel3 = 'Sector';
			break;
		case Brands.MULTINATIONAL:
			config.labelLevel1 = 'Municipio';
			config.labelLevel2 = 'Barrio';
			config.labelLevel3 = 'Sector';
			break;
		case Brands.PREMIER:
			config.labelLevel1 = 'Municipio';
			config.labelLevel2 = 'Barrio';
			config.labelLevel3 = 'Sector';
			break;
		default:
			break;
	}
	const locations = await new ConnectServicesClient().getLocalization(getCurrentCountry().toString());
	config.levels = JSON.stringify(locations?.data?.data?.provinces);
	localStorage.setItem('locations', JSON.stringify(config));
};

export const getLocationConfig = async () => {
	const config: LevelConfig = JSON.parse(localStorage.getItem('locations') || '{}');
	return config;
};

export const getLocationLevel1 = async () => {
	const config: LevelConfig = JSON.parse(localStorage.getItem('locations') || '{}');
	const levels = JSON.parse(config.levels || '{}');
	const dataLevel1 = Array<Option>();
	if (levels.length > 0)
		levels.map((topLevel: any) => {
			const level: Option = {
				id: topLevel.id.toString(),
				name: topLevel.name,
			};
			dataLevel1.push(level);
			return true;
		});
	return { label: config.labelLevel1, data: dataLevel1 };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getLocationLevel2 = async (level1Id: string) => {
	const config: LevelConfig = JSON.parse(localStorage.getItem('locations') || '{}');
	const levels = JSON.parse(config.levels || '{}');
	const dataLevel2 = Array<Option>();
	if (levels.length > 0) {
		const level1 = levels.filter((level: any) => level.id === +level1Id)[0];
		level1.cantons.map((level2: any) => {
			const level: Option = {
				id: level2.id.toString(),
				name: level2.name,
			};
			dataLevel2.push(level);
			return true;
		});
	}
	return { label: config.labelLevel2, data: dataLevel2 };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getLocationLevel3 = async (level1Id: string, level2Id: string) => {
	const config: LevelConfig = JSON.parse(localStorage.getItem('locations') || '{}');
	const levels = JSON.parse(config.levels || '{}');
	const dataLevel3 = Array<Option>();
	if (levels.length > 0) {
		const level1 = levels.filter((level: any) => level.id === +level1Id)[0];
		if (level1 !== undefined) {
			const level2 = level1.cantons.filter((level: any) => level.id === +level2Id)[0];
			level2.districts.map((level3: any) => {
				const level: Option = {
					id: level3.id.toString(),
					name: level3.name,
				};
				dataLevel3.push(level);
				return true;
			});
		}
	}
	return { label: config.labelLevel3, data: dataLevel3 };
};

export const uploadPhoto = async (
	photo: Photo,
	eventId: number,
	serviceId: string,
	inspectionId: any
): Promise<string | void> => {
	const data = {
		inspectionId: inspectionId,
		file: photo.dataUrl!,
		type: 'jpeg',
		applyResize: false,
		sectionId: +photo.section.toString(),
		countryCode: getCurrentCountry(),
		name: photo.name,
	};
	const clientRequest = new ConnectServicesClient();
	const response = await clientRequest.uploadImage(eventId, data);
	if (response.data.success) return response.data.data;
};

export const deleteImageFromBucket = async (data: {
	eventId: number;
	serviceId?: string;
	inspectionId: string;
	imageUrl: string;
}): Promise<void> => {
	await new ConnectServicesClient().deleteImage(data.eventId!, {
		inspectId: data.inspectionId,
		imageUrl: data.imageUrl,
	});
};

export const uploadMediaToServer = async (
	serviceNumber: string,
	name: string,
	file: File,
	section: number
): Promise<string> => {
	const fileExtension = `.${file.type.split('/')[1] || 'file'}`;
	const filename = `${name}${fileExtension}`;

	const response = await new ConnectServicesClient().uploadMedia(
		serviceNumber,
		String(section) + '_' + filename,
		fileExtension,
		file
	);

	const awsUrl = response.data.data;

	if (!awsUrl) {
		throw new Error('Error uploading media file');
	}

	return awsUrl;
};

export async function deleteMediaToServer(url: string): Promise<void> {
	await new ConnectServicesClient().deleteMediaFile(url);
}

export const uploadSurvey = async (data: SurveyPayload): Promise<void> => {
	try {
		await new ConnectServicesClient().submitSurvey(data);
	} catch (e) {
		//TODO: add error handle with Sentry
		//eslint-disable-next-line no-console
		console.log(e);
	}
};

export const genResumeEmail = async (eventRecord: string): Promise<void> => {
	try {
		await new ConnectServicesClient().genResumeEmail(eventRecord);
	} catch (e) {
		//TODO: add error handle with Sentry
		//eslint-disable-next-line no-console
		console.log(e);
	}
};

export const dataUrl2Blob = (dataUrl: string): Blob => {
	// Yes. This block was pasted from stackoverflow.
	const arr = dataUrl?.split(',');
	const mime = arr![0]?.match(/:(.*?);/)![1];
	const bstr = atob(arr![1]);
	let n = bstr.length;
	// eslint-disable-next-line no-undef
	const u8arr = new Uint8Array(n);
	while (n--) u8arr[n] = bstr.charCodeAt(n);
	return new Blob([u8arr], { type: mime });
};

export const getDamagesForSICForm = (damages: { [key in number]: string[] }, inspectionId: string): any[] => {
	const damagedAreas = getDamageStringByArea(damages);

	const damagesList: any[] = [];
	for (const key in damagedAreas) {
		// eslint-disable-next-line no-prototype-builtins
		if (damagedAreas.hasOwnProperty(key)) {
			const area = damagedAreas[key as DamageAreas];
			damagesList.push({
				Description: area.map((d) => buildDamageString(d.areaId, d.description)).join(' | '),
				DetailDamage: area.map((d) => ({ damageId: d.areaId, description: d.description })),
				EventId: inspectionId,
				VehiclePartId: String(getDamageAreaAsNumber(getDamageArea(+key))),
				VehicleType: 'A',
			});
		}
	}
	return damagesList;
};

export const getInsuredDamagesForSIC = (damages: { [key in number]: string[] }): any[] => {
	const damagedAreas = getDamageStringByArea(damages);

	const damagesList: any[] = [];
	for (const key in damagedAreas) {
		// eslint-disable-next-line no-prototype-builtins
		if (damagedAreas.hasOwnProperty(key)) {
			const area = damagedAreas[key as DamageAreas];
			damagesList.push({
				Description: area.map((d) => buildDamageString(d.areaId, d.description)).join(' | '),
				VehiclePartId: String(getDamageAreaAsNumber(key as DamageAreas)),
				Id: getDamageAreaAsNumber(key as DamageAreas),
			});
		}
	}
	return damagesList;
};

export const getCounterpartDamagesForSIC = (damages: { [key in number]: string[] }): string => {
	const damagedAreas = getDamageStringByArea(damages);

	const damagesList: string[] = [];
	for (const key in damagedAreas) {
		// eslint-disable-next-line no-prototype-builtins
		if (damagedAreas.hasOwnProperty(key)) {
			const area = damagedAreas[key as DamageAreas];
			damagesList.push(area.map((d) => buildDamageString(d.areaId, d.description)).join(' | '));
		}
	}
	return damagesList.join(' | ');
};

export const getDamageStringByArea = (
	damages: { [key in number]: string[] }
): {
	[area in DamageAreas]: { areaId: number; description: string }[];
} => {
	const areas: {
		[area in DamageAreas]: { areaId: number; description: string }[];
	} = {} as any;

	for (const area in damages) {
		const damageArea = getDamageArea(+area);
		// eslint-disable-next-line no-prototype-builtins
		if (damages.hasOwnProperty(area)) {
			const _damages = damages[area];
			if (!areas[damageArea]) areas[damageArea] = [];
			areas[damageArea].push({ areaId: +area, description: _damages.join(', ') });
		}
	}

	return areas;
};

export const buildDamageString = (areaKey: number, damages: string): string => {
	const area = getDamageArea(areaKey);
	return `Daños en la ${area} con el detalle: #${+areaKey} - ${damages}`;
};

export const parseStateToInspection = async (data: AppContext, finish = false): Promise<any> => {
	let idInspect: string | null = localStorage.getItem('inspectionId');
	idInspect =
		!idInspect || String(idInspect).length === 0
			? localStorage.getItem('inspectionId')
				? localStorage.getItem('inspectionId')
				: ''
			: idInspect;

	const modelState: AppContext = { ...data, inspectionId: idInspect };

	let dataModel = {};
	switch (BRAND) {
		case Brands.CSM:
			dataModel = inspectionModelCSM(modelState, finish);
			break;
		case Brands.ASSA:
		case Brands.LAFISE:
			dataModel = inspectionModelLafiseAndAssa(modelState, finish);
			break;
		case Brands.OCEANICA:
			dataModel = inspectionModelOceanica(modelState, finish);
			break;
		case Brands.QUALITAS:
			dataModel = inspectionModelQualitas(modelState, finish);
			break;
		case Brands.MULTINATIONAL:
			dataModel = inspectionModelMultinational(modelState, finish);
			break;
		case Brands.PREMIER:
			dataModel = inspectionModelPREMIER(modelState, finish);
			break;
		default:
			dataModel = inspectionModel(modelState, finish);
			break;
	}

	return dataModel;
};

export const parseServerData = async (data: any, context: AppContext): Promise<AppContext> => {
	// Validate location levels
	let defaultLevel1: string = data.service?.locations?.situation?.province || '';
	let defaultLevel2: string = data.service?.locations?.situation?.canton || '';
	let defaultLevel3: string = data.service?.locations?.situation?.district || '';

	const { level1, level2, level3 } = await getCodeLocations(defaultLevel1, defaultLevel2, defaultLevel3);

	defaultLevel1 = level1 && level1.length > 0 ? level1 : defaultLevel1;
	defaultLevel2 = level2 && level2.length > 0 ? level2 : defaultLevel2;
	defaultLevel3 = level3 && level3.length > 0 ? level3 : defaultLevel3;

	console.log('GET::Situation::', data.service?.locations?.situation);
	console.log('GET::Levels:: ', defaultLevel1, defaultLevel2, defaultLevel3);

	let pServData = {
		inspectionId: data?.inspection?.id || localStorage.getItem('inspectionId'),
		inspectionDate: data?.inspection?.date ? new Date(+data.inspection.date) : null,
		serviceId: data?.service?._id,
		accountId: data?.service?.accountId,
		eventId: data?.inspection?.EventRecord,
		ClaimantType: data?.inspection?.ClaimantType,
		signature: data?.inspection?.Signatures || '',
		location: {
			coords: {
				lat:
					data?.service?.locations?.situation?.lat || data?.inspection?.locations?.situation?.lat || DEFAULT_COORDS.lat,
				lng:
					data?.service?.locations?.situation?.lng || data?.inspection?.locations?.situation?.lng || DEFAULT_COORDS.lng,
			},
			mapSize: { height: null, width: null },
			address: data?.inspection?.locations?.situation?.address || data?.service?.locations?.situation?.address || '',
			newLocation: false,
			selectedLocation: 'default' as string | null,
			originalCoords: {
				lat:
					data?.service?.locations?.situation?.lat || data?.inspection?.locations?.situation?.lat || DEFAULT_COORDS.lat,
				lng:
					data?.service?.locations?.situation?.lng || data?.inspection?.locations?.situation?.lng || DEFAULT_COORDS.lng,
			},
			originalAddress:
				data?.inspection?.location?.originalAddress ||
				data?.inspection?.locations?.situation?.address ||
				data?.service?.locations?.situation?.address ||
				'',
			level1: validateStr(data?.inspection?.location?.level1) || validateStr(defaultLevel1) || '',
			level2: validateStr(data?.inspection?.location?.level2) || validateStr(defaultLevel2) || '',
			level3: validateStr(data?.inspection?.location?.level3) || validateStr(defaultLevel3) || '',
			isGPSActive: data?.inspection?.location?.isGPSActive,
			isManualLocation: data?.inspection?.location?.isManualLocation,
		},
		insured: {
			narration: data?.inspection?.narracionHechos || data?.inspection?.EventStory || '',
			narrationAdditional: data?.inspection?.narrationAdditional || '',
			damages: data?.inspection?.insuredDamages || {},
			fields: {
				name: data?.inspection?.nombreConductor || `${data?.service?.firstname} ${data?.service?.lastname}` || '',
				firstName: data?.inspection?.nombreConductor || data?.service?.firstname || '',
				lastName: data?.inspection?.apellidosConductor || data?.service?.lastname || '',
				phone: data?.service?.phone || '',
				plate: data?.inspection?.VehicleLisencePlate || '',
				make: data?.inspection?.OwnerVehicleBrand || '',
				model: data?.inspection?.OwnerVehicleModel || '',
				year: data?.inspection?.OwnerVehicleYear || '',
				email: data?.inspection?.OwnerEmail || '',
				color: data?.inspection?.OwnerVehicleColor || data?.service?.color || '',
				sex: data?.inspection?.DatosVehiculo1?.DuenoSexo || '',
				license: data?.inspection?.DatosVehiculo1?.DuenoLicencia || '',
				postalCode: '',
				urbanization:
					data?.inspection?.EventAddress ||
					data?.inspection?.locations?.situation?.address ||
					data?.service?.locations?.situation?.address ||
					{},
				driverName: data?.inspection?.DriverName || '',
				driverRelationship: data?.inspection?.DriverRelationship || '',
				driverLastName: data?.inspection?.DriverLastNames || '',
				driverPhone: data?.inspection?.DriverPhone || data?.inspection?.DriverMovil || '',
				driverEmail: data?.inspection?.DriverEmail || '',
				driverSex: data?.inspection?.DriverSex || '',
				driverLicense: data?.inspection?.DriverLicense || '',
				isOtherDriver: false,
			},
		} as any,
		counterpart: {
			damages: data?.inspection?.counterpartDamages || {},
			fields: {
				name: data?.inspection?.Third?.[0]?.EventThirdName || data?.inspection?.propietarioC1 || '',
				phone: data?.inspection?.telefonoC1_1 || data?.inspection?.telefonoC1_2 || '',
				plate: data?.inspection?.Third?.[0]?.EventThirdLicensePlate || data?.inspection?.placaC1 || '',
				make: data?.inspection?.Third?.[0]?.EventThirdBrand || data?.inspection?.marcaC1 || '',
				model: data?.inspection?.Third?.[0]?.EventThirdModel || data?.inspection?.modeloC1 || '',
				year: data?.inspection?.Third?.[0]?.EventThirdAnno || data?.inspection?.anoC1 || '',
				email: data?.inspection?.emailC1 || '',
				color: data?.inspection?.Third?.[0]?.EventThirdColor || data?.inspection?.colorC1 || '',
			},
		} as any,
		injured: {} as any,
		photos: data?.inspection?.damagesPhotos,
		responsibility: {
			responsible: data?.inspection?.responsibility,
			legal: data?.inspection?.asistenciaLegal,
			insuredInitials: data?.inspection?.draw4,
		},
		accidentDate: data?.inspection?.IncidentDate,
		accidentInfo: data?.inspection?.DatosDelAccidente,
		selectedAccidentType: '',
		selectedIsVehicle: false as boolean | null,
		policivePart: false,
		numberPart: '',
		galleryPhotos: data?.inspection?.galleryPhotos || [],
		selectedIsDeposit: false,
		directDeposit: {
			claimantName: '',
			claimantNumber: '',
			policy: '',
			financialInstitution: '',
			routeNumber: '',
			branchOffice: '',
			personalContact: '',
			phone: '',
			accountType: '',
			signature: '',
		},
	};

	switch (BRAND) {
		case Brands.CSM:
			pServData = {
				...pServData,
				location: {
					...pServData.location,
					selectedLocation: data?.inspection?.location?.selectedLocation || null,
					originalAddress: undefined,
					level1: '',
					level2: '',
					level3: '',
					isGPSActive: null,
					isManualLocation: null,
				},
				insured: {
					...pServData.insured,
					fields: {
						...pServData.insured.fields,
						sex: '',
						license: '',
						urbanization:
							data?.inspection?.EventAddress ||
							data?.inspection?.locations?.situation?.address ||
							data?.service?.locations?.situation?.address ||
							'',
						driverName: data?.inspection?.DatosVehiculo1?.ConductorNombre || '',
						driverLastName: data?.inspection?.DatosVehiculo1?.ConductorApellidos || '',
						driverPhone:
							data?.inspection?.DatosVehiculo1?.ConductorTelCelular ||
							data?.inspection?.DatosVehiculo1?.ConductorTelResidencia ||
							data?.inspection?.DatosVehiculo1?.ConductorTelTrabajo ||
							'',
						driverSex: data?.inspection?.DatosVehiculo1?.ConductorSexo || '',
						driverLicense: data?.inspection?.DatosVehiculo1?.ConductorLicencia || '',
						driverPostalCode: data?.inspection?.DatosVehiculo1?.ConductorCodigoPostal || '',
						driverUrbanization:
							data?.inspection?.DatosVehiculo1?.ConductorDireccion1 ||
							data?.inspection?.DatosVehiculo1?.ConductorDireccion2 ||
							'',
						isOtherDriver: false,
					},
				},
				counterpart: {},
				injured: {
					narration: data?.dataVehiculo2?.Ocurrido || '',
					damages: data?.inspection?.injuredDamages || {},
					fields: {
						name:
							`${data?.inspection?.DatosVehiculo2?.DuenoNombre} ${data?.inspection?.DatosVehiculo2?.DuenoApellidos}` ||
							'',
						firstName: data?.inspection?.DatosVehiculo2?.DuenoNombre || '',
						lastName: data?.inspection?.DatosVehiculo2?.DuenoApellidos || '',
						phone:
							data?.inspection?.DatosVehiculo2?.DuenoTelCelular ||
							data?.inspection?.DatosVehiculo2?.DuenoTelResidencia ||
							data?.inspection?.DatosVehiculo2?.DuenoTelTrabajo ||
							'',
						plate: data?.inspection?.DatosVehiculo2?.Tablilla || '',
						make: data?.inspection?.DatosVehiculo2?.Marca || '',
						model: data?.inspection?.DatosVehiculo2?.Modelo || '',
						year: data?.inspection?.DatosVehiculo2?.Ano || '',
						email: data?.inspection?.DatosVehiculo2?.DuenoEmail || '',
						color: data?.inspection?.DatosVehiculo2?.Color || '',
						sex:
							data?.inspection?.DatosVehiculo2?.DuenoSexo === '1'
								? '2'
								: data?.inspection?.DatosVehiculo2?.DuenoSexo === '2'
								? '1'
								: data?.inspection?.DatosVehiculo2?.DuenoSexo || '',
						license: data?.inspection?.DatosVehiculo2?.DuenoLicencia || '',
						postalCode: data?.inspection?.DatosVehiculo2?.DuenoCodigoPostal || '',
						urbanization:
							data?.inspection?.DatosVehiculo2?.DuenoDireccion1 ||
							data?.inspection?.DatosVehiculo2?.DuenoDireccion2 ||
							'',
						driverName: data?.inspection?.DatosVehiculo2?.ConductorNombre || '',
						driverLastName: data?.inspection?.DatosVehiculo2?.ConductorApellidos || '',
						driverPhone:
							data?.inspection?.DatosVehiculo2?.ConductorTelCelular ||
							data?.inspection?.DatosVehiculo2?.ConductorTelResidencia ||
							data?.inspection?.DatosVehiculo2?.ConductorTelTrabajo ||
							'',
						driverEmail: data?.inspection?.DatosVehiculo2?.ConductorEmail,
						driverSex:
							data?.inspection?.DatosVehiculo2?.ConductorSexo === '1'
								? '2'
								: data?.inspection?.DatosVehiculo2?.ConductorSexo === '2'
								? '1'
								: data?.inspection?.DatosVehiculo2?.ConductorSexo || '',
						driverLicense: data?.inspection?.DatosVehiculo2?.ConductorLicencia || '',
						driverPostalCode: data?.inspection?.DatosVehiculo2?.ConductorCodigoPostal || '',
						driverUrbanization:
							data?.inspection?.DatosVehiculo2?.ConductorDireccion1 ||
							data?.inspection?.DatosVehiculo2?.ConductorDireccion2 ||
							'',
						isOtherDriver: false,
					},
				},
				selectedIsVehicle: false,
				directDeposit: {
					claimantName: data?.inspection?.DepositoDirecto?.NombreReclamante || '',
					claimantNumber: data?.inspection?.DepositoDirecto?.NumeroReclamacion || '',
					policy: data?.inspection?.DepositoDirecto?.NumeroPoliza || '',
					financialInstitution: data?.inspection?.DepositoDirecto?.NombreInstitucionFinanciera || '',
					routeNumber: data?.inspection?.DepositoDirecto?.NumeroRuta || '',
					branchOffice: data?.inspection?.DepositoDirecto?.Sucursal || '',
					personalContact: data?.inspection?.DepositoDirecto?.PersonalContacto || '',
					phone: data?.inspection?.DepositoDirecto?.Telefono || '',
					accountType: data?.inspection?.DepositoDirecto?.TipoCuenta || '',
					signature: data?.inspection?.DepositoDirecto?.FirmaDeposito || '',
				},
			};
			break;
		case Brands.LAFISE:
		case Brands.OCEANICA:
			pServData = {
				inspectionId: data?.inspection?.id || localStorage.getItem('inspectionId'),
				inspectionDate: data?.inspection?.date ? new Date(+data.inspection.date) : null,
				serviceId: data?.service?._id,
				accountId: data?.service?.accountId,
				eventId: data?.inspection?.EventRecord,
				ClaimantType: data?.inspection?.ClaimantType,
				signature: data?.inspection?.Signatures || '',
				location: {
					coords: {
						lat:
							data?.service?.locations?.situation?.lat ||
							data?.inspection?.locations?.situation?.lat ||
							DEFAULT_COORDS.lat,
						lng:
							data?.service?.locations?.situation?.lng ||
							data?.inspection?.locations?.situation?.lng ||
							DEFAULT_COORDS.lng,
					},
					mapSize: { height: null, width: null },
					address: data?.service?.locations?.situation?.address || '',
					newLocation: false,
					selectedLocation:
						data?.inspection?.InspeccionLugarAccidente === null ||
						data?.inspection?.InspeccionLugarAccidente === undefined
							? null
							: data?.inspection?.InspeccionLugarAccidente
							? 'default'
							: 'other',
					originalCoords: {
						lat:
							data?.service?.locations?.situation?.lat ||
							data?.inspection?.locations?.situation?.lat ||
							DEFAULT_COORDS.lat,
						lng:
							data?.service?.locations?.situation?.lng ||
							data?.inspection?.locations?.situation?.lng ||
							DEFAULT_COORDS.lng,
					},
					originalAddress:
						data?.inspection?.location?.originalAddress ||
						data?.inspection?.locations?.situation?.address ||
						data?.service?.locations?.situation?.address ||
						'',
					level1: validateStr(data?.inspection?.location?.level1) || validateStr(defaultLevel1) || '',
					level2: validateStr(data?.inspection?.location?.level2) || validateStr(defaultLevel2) || '',
					level3: validateStr(data?.inspection?.location?.level3) || validateStr(defaultLevel3) || '',
					isGPSActive: data?.inspection?.location?.isGPSActive,
					isManualLocation: data?.inspection?.location?.isManualLocation,
				},
				insured: {
					narration: data?.inspection?.narracionHechos || data?.inspection?.EventStory || '',
					narrationAdditional: data?.inspection?.narrationAdditional || '',
					alertNarration: data?.inspection?.RazonAvisoInmediato || '',
					damages: data?.inspection?.insuredDamages || {},
					fields: {
						name: data?.inspection?.nombreConductor || `${data?.service?.firstname} ${data?.service?.lastname}` || '',
						firstName: data?.inspection?.nombreConductor || data?.service?.firstname || '',
						lastName: data?.inspection?.apellidosConductor || data?.service?.lastname || '',
						phone: data?.service?.phone || '',
						plate: data?.inspection?.VehicleLisencePlate || '',
						make: data?.inspection?.OwnerVehicleBrand || '',
						model: data?.inspection?.OwnerVehicleModel || '',
						year: data?.inspection?.OwnerVehicleYear || '',
						email: data?.inspection?.OwnerEmail || '',
						color: data?.inspection?.OwnerVehicleColor || data?.service?.color || '',
						sex: data?.inspection?.DatosVehiculo1?.DuenoSexo || '',
						license: data?.inspection?.DatosVehiculo1?.DuenoLicencia || '',
						postalCode: '',
						urbanization:
							data?.inspection?.EventAddress ||
							data?.inspection?.locations?.situation?.address ||
							data?.service?.locations?.situation?.address ||
							{},
						driverName: data?.inspection?.DriverName || '',
						driverRelationship: data?.inspection?.DriverRelationship || '',
						driverLastName: data?.inspection?.DriverLastNames || '',
						driverPhone: data?.inspection?.DriverPhone || data?.inspection?.DriverMovil || '',
						driverEmail: data?.inspection?.DriverEmail || '',
						driverSex: data?.inspection?.DriverSex || '',
						driverLicense: data?.inspection?.DriverLicense || '',
						isOtherDriver: false,
					},
				} as any,
				counterpart: {
					damages: data?.inspection?.counterpartDamages || {},
					fields: {
						name: data?.inspection?.Third?.[0]?.EventThirdName || data?.inspection?.propietarioC1 || '',
						phone: data?.inspection?.telefonoC1_1 || data?.inspection?.telefonoC1_2 || '',
						plate: data?.inspection?.Third?.[0]?.EventThirdLicensePlate || data?.inspection?.placaC1 || '',
						make: data?.inspection?.Third?.[0]?.EventThirdBrand || data?.inspection?.marcaC1 || '',
						model: data?.inspection?.Third?.[0]?.EventThirdModel || data?.inspection?.modeloC1 || '',
						year: data?.inspection?.Third?.[0]?.EventThirdAnno || data?.inspection?.anoC1 || '',
						email: data?.inspection?.emailC1 || '',
						color: data?.inspection?.Third?.[0]?.EventThirdColor || data?.inspection?.colorC1 || '',
					},
				} as any,
				injured: {} as any,
				photos: data?.inspection?.damagesPhotos,
				responsibility: {
					responsible: data?.inspection?.responsibility,
					legal: data?.inspection?.asistenciaLegal,
					insuredInitials: data?.inspection?.draw4,
				},
				accidentDate: data?.inspection?.IncidentDate,
				accidentInfo: data?.inspection?.DatosDelAccidente,
				selectedAccidentType: data?.inspection?.selectedAccidentType || null,
				selectedIsVehicle:
					context?.selectedIsVehicle === null || context?.selectedIsVehicle === undefined
						? null
						: context.selectedIsVehicle,
				policivePart:
					data?.inspection?.TrafficPolicePresented !== null ? data?.inspection?.TrafficPolicePresented : null,
				numberPart: data?.inspection?.EventTrafficTicket || '',
				galleryPhotos: data?.inspection?.galleryPhotos || [],
				selectedIsDeposit: false,
				directDeposit: {
					claimantName: '',
					claimantNumber: '',
					policy: '',
					financialInstitution: '',
					routeNumber: '',
					branchOffice: '',
					personalContact: '',
					phone: '',
					accountType: '',
					signature: '',
				},
			};
			break;
		case Brands.MULTINATIONAL:
			pServData = {
				...pServData,
				location: {
					...pServData.location,
					selectedLocation: data?.inspection?.location?.selectedLocation || null,
					originalAddress: undefined,
					level1: '',
					level2: '',
					level3: '',
					isGPSActive: null,
					isManualLocation: null,
				},
				insured: {
					...pServData.insured,
					narration: data?.inspection?.narracionHechos || data?.inspection?.EventStory || '',
					narrationAdditional: data?.inspection?.narrationAdditional || '',
					alertNarration: data?.inspection?.RazonAvisoInmediato || '',
					damages: data?.inspection?.insuredDamages || {},
					fields: {
						name: data?.inspection?.nombreConductor || `${data?.service?.firstname} ${data?.service?.lastname}` || '',
						firstName: data?.inspection?.nombreConductor || data?.service?.firstname || '',
						lastName: data?.inspection?.apellidosConductor || data?.service?.lastname || '',
						phone: data?.service?.phone || '',
						plate: data?.inspection?.VehicleLisencePlate || '',
						make: data?.inspection?.OwnerVehicleBrand || '',
						model: data?.inspection?.OwnerVehicleModel || '',
						year: data?.inspection?.OwnerVehicleYear || '',
						email: data?.inspection?.OwnerEmail || '',
						color: data?.inspection?.OwnerVehicleColor || data?.service?.color || '',
						sex: data?.inspection?.DatosVehiculo1?.DuenoSexo || '',
						license: data?.inspection?.DatosVehiculo1?.DuenoLicencia || '',
						postalCode: '',
						urbanization:
							data?.inspection?.EventAddress ||
							data?.inspection?.locations?.situation?.address ||
							data?.service?.locations?.situation?.address ||
							{},
						driverName: data?.inspection?.DriverName || '',
						driverRelationship: data?.inspection?.DriverRelationship || '',
						driverLastName: data?.inspection?.DriverLastNames || '',
						driverPhone: data?.inspection?.DriverPhone || data?.inspection?.DriverMovil || '',
						driverEmail: data?.inspection?.DriverEmail || '',
						driverSex: data?.inspection?.DriverSex || '',
						driverLicense: data?.inspection?.DriverLicense || '',
						isOtherDriver: false,
						vin: data?.inspection?.VehicleVin || '',
					},
				},
				counterpart: {},
				injured: {
					narration: '',
					damages: data?.inspection?.injuredDamages || {},
					fields: {
						name: `${data?.inspection?.OwnerName} ${data?.inspection?.OwnerName}` || '',
						firstName: data?.service?.firstname || '',
						lastName: data?.service?.lastname || '',
						phone: data?.inspection?.DriverPhone || '',
						plate: data?.inspection?.VehicleLisencePlate || '',
						make: data?.inspection?.OwnerVehicleBrand || '',
						model: data?.inspection?.OwnerVehicleModel || '',
						year: data?.inspection?.OwnerVehicleYear || '',
						email: data?.inspection?.ContactEmail || '',
						color: data?.inspection?.OwnerVehicleColor || '',
						sex: '',
						license: '',
						postalCode: data?.inspection?.locations?.situation?.addressComponents?.postalCode || '',
						urbanization:
							data?.inspection?.EventAddress ||
							data?.inspection?.locations?.situation?.address ||
							data?.service?.locations?.situation?.address ||
							'',
						driverName: data?.inspection?.DriverName || '',
						driverRelationship: data?.inspection?.DriverRelationship || '',
						driverLastName: data?.inspection?.DriverLastNames || '',
						driverPhone: data?.inspection?.DriverPhone || data?.inspection?.DriverMovil || '',
						driverEmail: data?.inspection?.DriverEmail || '',
						driverSex: data?.inspection?.DriverSex || '',
						driverLicense: data?.inspection?.DriverLicense || '',
						isOtherDriver: false,
						vin: data?.inspection?.VehicleVin || '',
					},
				},
				selectedIsVehicle: false,
				directDeposit: {
					claimantName: data?.inspection?.DepositoDirecto?.NombreReclamante || '',
					claimantNumber: data?.inspection?.DepositoDirecto?.NumeroReclamacion || '',
					policy: data?.inspection?.DepositoDirecto?.NumeroPoliza || '',
					financialInstitution: data?.inspection?.DepositoDirecto?.NombreInstitucionFinanciera || '',
					routeNumber: data?.inspection?.DepositoDirecto?.NumeroRuta || '',
					branchOffice: data?.inspection?.DepositoDirecto?.Sucursal || '',
					personalContact: data?.inspection?.DepositoDirecto?.PersonalContacto || '',
					phone: data?.inspection?.DepositoDirecto?.Telefono || '',
					accountType: data?.inspection?.DepositoDirecto?.TipoCuenta || '',
					signature: data?.inspection?.DepositoDirecto?.FirmaDeposito || '',
				},
			};
			break;
		case Brands.PREMIER:
			pServData = {
				...pServData,
				location: {
					...pServData.location,
					selectedLocation: data?.inspection?.location?.selectedLocation || null,
					originalAddress: undefined,
					level1: '',
					level2: '',
					level3: '',
					isGPSActive: null,
					isManualLocation: null,
				},
				insured: {
					...pServData.insured,
					narration: data?.inspection?.narracionHechos || data?.inspection?.EventStory || '',
					narrationAdditional: data?.inspection?.narrationAdditional || '',
					alertNarration: data?.inspection?.RazonAvisoInmediato || '',
					damages: data?.inspection?.insuredDamages || {},
					fields: {
						name: data?.inspection?.nombreConductor || `${data?.service?.firstname} ${data?.service?.lastname}` || '',
						firstName: data?.inspection?.nombreConductor || data?.service?.firstname || '',
						lastName: data?.inspection?.apellidosConductor || data?.service?.lastname || '',
						phone: data?.service?.phone || '',
						plate: data?.inspection?.VehicleLisencePlate || '',
						make: data?.inspection?.OwnerVehicleBrand || '',
						model: data?.inspection?.OwnerVehicleModel || '',
						year: data?.inspection?.OwnerVehicleYear || '',
						email: data?.inspection?.OwnerEmail || '',
						color: data?.inspection?.OwnerVehicleColor || data?.service?.color || '',
						sex: data?.inspection?.DatosVehiculo1?.DuenoSexo || '',
						license: data?.inspection?.DatosVehiculo1?.DuenoLicencia || '',
						postalCode: '',
						urbanization:
							data?.inspection?.EventAddress ||
							data?.inspection?.locations?.situation?.address ||
							data?.service?.locations?.situation?.address ||
							{},
						driverName: data?.inspection?.DriverName || '',
						driverRelationship: data?.inspection?.DriverRelationship || '',
						driverLastName: data?.inspection?.DriverLastNames || '',
						driverPhone: data?.inspection?.DriverPhone || data?.inspection?.DriverMovil || '',
						driverEmail: data?.inspection?.DriverEmail || '',
						driverSex: data?.inspection?.DriverSex || '',
						driverLicense: data?.inspection?.DriverLicense || '',
						isOtherDriver: false,
						vin: data?.inspection?.VehicleVin || '',
					},
				},
				counterpart: {},
				injured: {
					narration: '',
					damages: data?.inspection?.injuredDamages || {},
					fields: {
						name: `${data?.inspection?.OwnerName} ${data?.inspection?.OwnerName}` || '',
						firstName: data?.service?.firstname || '',
						lastName: data?.service?.lastname || '',
						phone: data?.inspection?.DriverPhone || '',
						plate: data?.inspection?.VehicleLisencePlate || '',
						make: data?.inspection?.OwnerVehicleBrand || '',
						model: data?.inspection?.OwnerVehicleModel || '',
						year: data?.inspection?.OwnerVehicleYear || '',
						email: data?.inspection?.ContactEmail || '',
						color: data?.inspection?.OwnerVehicleColor || '',
						sex: '',
						license: '',
						postalCode: data?.inspection?.locations?.situation?.addressComponents?.postalCode || '',
						urbanization:
							data?.inspection?.EventAddress ||
							data?.inspection?.locations?.situation?.address ||
							data?.service?.locations?.situation?.address ||
							'',
						driverName: data?.inspection?.DriverName || '',
						driverRelationship: data?.inspection?.DriverRelationship || '',
						driverLastName: data?.inspection?.DriverLastNames || '',
						driverPhone: data?.inspection?.DriverPhone || data?.inspection?.DriverMovil || '',
						driverEmail: data?.inspection?.DriverEmail || '',
						driverSex: data?.inspection?.DriverSex || '',
						driverLicense: data?.inspection?.DriverLicense || '',
						isOtherDriver: false,
						vin: data?.inspection?.VehicleVin || '',
					},
				},
				selectedIsVehicle: false,
				directDeposit: {
					claimantName: data?.inspection?.DepositoDirecto?.NombreReclamante || '',
					claimantNumber: data?.inspection?.DepositoDirecto?.NumeroReclamacion || '',
					policy: data?.inspection?.DepositoDirecto?.NumeroPoliza || '',
					financialInstitution: data?.inspection?.DepositoDirecto?.NombreInstitucionFinanciera || '',
					routeNumber: data?.inspection?.DepositoDirecto?.NumeroRuta || '',
					branchOffice: data?.inspection?.DepositoDirecto?.Sucursal || '',
					personalContact: data?.inspection?.DepositoDirecto?.PersonalContacto || '',
					phone: data?.inspection?.DepositoDirecto?.Telefono || '',
					accountType: data?.inspection?.DepositoDirecto?.TipoCuenta || '',
					signature: data?.inspection?.DepositoDirecto?.FirmaDeposito || '',
				},
			};
			break;
		default:
			break;
	}

	return pServData as AppContext;
};

export function redactDataUrl(photos: Photo[]): Photo[] {
	return photos.map((photo) => ({ ...photo, dataUrl: '' }));
}

export const getThirdData = (data: AppContext): any => {
	if (data.selectedIsVehicle && data.typeIsVehicle === 'isVehicle') {
		return [
			{
				EventThirdColor: data?.counterpart?.fields?.color || '',
				EventThirdEmail1: data?.counterpart?.fields?.email || '',
				EventThirdBrand: data?.counterpart?.fields?.make || '',
				EventThirdModel: data?.counterpart?.fields?.model || '',
				EventThirdAnno: data?.counterpart?.fields?.year || '',
				EventThirdLicensePlate: data?.counterpart?.fields?.plate || '',
				EventThirdName: data?.counterpart?.fields?.name || '',
				EventThirdDriver: data?.counterpart?.fields?.name || '',
				EventThirdIdentification: '',
				EventThirdIdentification2: '',
				EventThirdPhone: data?.counterpart?.fields?.phone || '',
				EventThirdPhone2: data?.counterpart?.fields?.phone || '',
				InjuryDescription: getCounterpartDamagesForSIC(data.counterpart.damages), // damages description para connectservices
				IsDriverAffected: true,
			},
		];
	}

	return null;
};

export const inspectionModel = (data: AppContext, finish = false): any => {
	let defaultModel = {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: data?.insured?.narration || '',
		narrationAdditional: data?.insured?.narrationAdditional || '',
		EventStory: data?.insured?.narration || '',
		ReportStory: data?.insured?.narration || '',
		//Conductor
		DriverName: data?.insured?.fields?.driverName || '',
		DriverLastNames: data?.insured?.fields?.driverLastName || '',
		DriverEmail: data?.insured?.fields?.driverEmail || '',
		DriverRelationship: data?.insured?.fields?.driverRelationship || '',
		DriverLicense: data?.insured?.fields?.driverLicense || '',
		DriverPhone: data?.insured?.fields?.driverPhone || '',
		DriverMovil: data?.insured?.fields?.driverPhone || '',
		DriverSex: data?.insured?.fields?.driverSex || '',
		DatosVehiculo1: {
			DuenoNombre: data?.insured?.fields?.firstName || '',
			DuenoApellidos: data?.insured?.fields?.lastName || '',
			DuenoSexo: data?.insured?.fields?.sex || '',
			DuenoLicencia: data?.insured?.fields?.license || '',
		},
		//counterpart
		colorC1: data?.counterpart?.fields?.color || '',
		emailC1: data?.counterpart?.fields?.email || '',
		marcaC1: data?.counterpart?.fields?.make || '',
		modeloC1: data?.counterpart?.fields?.model || '',
		anoC1: data?.counterpart?.fields?.year || '',
		placaC1: data?.counterpart?.fields?.plate || '',
		propietarioC1: data?.counterpart?.fields?.name || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_1: data?.counterpart?.fields?.phone || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_2: data?.counterpart?.fields?.phone || '',
		thirdTypeC1: 'Driver',

		Third: getThirdData(data),
		ThirdAdditional: getThirdData(data),
		hasThird: data.selectedIsVehicle && data.typeIsVehicle === 'isVehicle',

		// damages para sic form
		injured: [
			[...getDamagesForSICForm(data.insured.damages, data.inspectionId!)], // insured has to be first
			[...getDamagesForSICForm(data.counterpart.damages, data.inspectionId!)], // counterpart has to be second
		],

		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),

		insuredDamages: data.insured.damages,
		counterpartDamages: data.counterpart.damages,
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_PTY',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress:
			data?.location?.isManualLocation === true ? data?.accidentInfo?.Lugar : data?.location?.address || '',
		EventAddress:
			data?.location?.isManualLocation === true
				? data?.accidentInfo?.Lugar
				: (data?.location?.selectedLocation === 'default'
						? data?.location?.address
						: data?.location?.originalAddress) || '',
		location: data?.location || {},
		// responsibility
		partePoliciva: data?.responsibility?.responsible === Responsible.NONE ? 'si' : 'no',
		IndTicket: data?.responsibility?.responsible === Responsible.NONE,
		indFUD: data?.responsibility?.responsible === Responsible.NONE ? 'no' : 'si',
		IndFUD: data?.responsibility?.responsible !== Responsible.NONE,
		responsibility: data?.responsibility?.responsible,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		IndAutoGestion: true, // always true
		damagesPhotos: redactDataUrl(data.photos),
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		finish,
	};
	if (
		data?.location?.level1.length > 0 &&
		data?.location?.level2.length > 0 &&
		data?.location?.level3.length > 0 //&&
		//data?.location?.isManualLocation === true
	) {
		if (data?.location?.selectedLocation === 'default') {
			const newObj = {
				...defaultModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.accidentInfo?.Lugar,
				EventProvinceId: data?.location?.level1,
				EventCantonId: data?.location?.level2,
				EventDistrictId: data?.location?.level3,
				locations: {
					situation: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			defaultModel = newObj;
		} else {
			const newObj = {
				...defaultModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.location?.originalAddress || '',
				EventProvinceId: data?.location?.customLevel1,
				EventCantonId: data?.location?.customLevel2,
				EventDistrictId: data?.location?.customLevel3,
				locations: {
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			defaultModel = newObj;
		}
	}
	return defaultModel;
};

export const inspectionModelCSM = (data: AppContext, finish = false): any => {
	const currentDate = new Date();
	const formatDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
	return {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		DriverName: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: (data?.insured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
		EventStory: data?.insured?.narration || '',
		AccidentDateOccurred: data?.accidentDate || null,
		DatosDelAccidente: {
			AM: data?.accidentInfo?.AM || '',
			Barrio: data?.accidentInfo?.Barrio || '',
			Carretera: data?.accidentInfo?.Carretera || '',
			Codificacion: data?.accidentInfo?.Codificacion || '',
			Fecha: data?.accidentInfo?.Fecha || '',
			Hora: data?.accidentInfo?.Hora || '',
			Kilometro: data?.accidentInfo?.Kilometro || '',
			Lugar: data?.accidentInfo?.Lugar || '',
			LugarDelAccidente: data?.location?.selectedLocation || '',
			Municipio: data?.accidentInfo?.Municipio || '',
			PM: data?.accidentInfo?.PM || '',
			Vehiculos: data?.accidentInfo?.Vehiculos || '',
			FirmaCroquis: data?.signature || '',
		},
		// injured
		DatosVehiculo2: {
			DuenoNombre: data?.injured?.fields?.firstName || '',
			DuenoApellidos: data?.injured?.fields?.lastName || '',
			// Mjolnir 🔨
			DuenoSexo:
				data?.injured?.fields?.sex === '1'
					? '2'
					: data?.injured?.fields?.sex === '2'
					? '1'
					: data?.injured?.fields?.sex || '',
			DuenoLicencia: data?.injured?.fields?.license || '',
			DuenoDireccion1: data?.injured?.fields?.urbanization || '',
			DuenoCodigoPostal: data?.injured?.fields?.postalCode || '',
			DuenoTelCelular: data?.injured?.fields?.phone || '',
			DuenoTelTrabajo: data?.injured?.fields?.phone || '',
			DuenoEmail: data?.injured?.fields?.email || '',
			ConductorNombre: data?.injured?.fields?.driverName || '',
			ConductorApellidos: data?.injured?.fields?.driverLastName || '',
			ConductorSexo:
				data?.injured?.fields?.driverSex === '1'
					? '2'
					: data?.injured?.fields?.driverSex === '2'
					? '1'
					: data?.injured?.fields?.driverSex || '',
			ConductorLicencia: data?.injured?.fields?.driverLicense || '',
			ConductorDireccion1: data?.injured?.fields?.driverUrbanization || '',
			ConductorCodigoPostal: data?.injured?.fields?.driverPostalCode || '',
			ConductorTelTrabajo: data?.injured?.fields?.driverPhone,
			ConductorTelCelular: data?.injured?.fields?.driverPhone,
			ConductorVencimientoMarbete: data?.injured?.fields?.driverMarbete || '',
			Millaje: data?.injured?.fields?.driverMileage || '',
			ConductorEmail: data?.injured?.fields?.driverEmail,
			Marca: data?.injured?.fields?.make || '',
			Modelo: data?.injured?.fields?.model || '',
			Ano: data?.injured?.fields?.year || '',
			Color: data?.injured?.fields?.color || '',
			Tablilla: data?.injured?.fields?.plate || '',
			Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
			IsOtherDriver: data?.injured?.fields?.isOtherDriver,
		},
		// damages para sic form
		injured: [
			[...getDamagesForSICForm(data.injured.damages, data.inspectionId!)], // insured has to be first
		],
		injuredDamages: data.injured.damages,

		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),
		insuredDamages: data.insured.damages,
		counterpartDamages: {},
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_PR',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress: data?.location?.address || '',
		EventAddress: data?.location?.address || '',
		location: data?.location || {},
		locations: {
			situation: {
				address: data?.location?.address || '',
				province: undefined, // validar si lo queremos enviar desde Helios
				canton: undefined, // validar si lo queremos enviar desde Helios
				district: undefined, // validar si lo queremos enviar desde Helios
			},
		},
		// responsibility
		partePoliciva: data?.responsibility?.responsible === Responsible.NONE ? 'si' : 'no',
		IndTicket: data?.responsibility?.responsible === Responsible.NONE,
		indFUD: data?.responsibility?.responsible === Responsible.NONE ? 'no' : 'si',
		IndFUD: data?.responsibility?.responsible !== Responsible.NONE,
		responsibility: data?.responsibility?.responsible,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		Firmas: {
			FirmaDueno1: null,
			FechaDueno1: null,
			FirmaDueno2: data?.signature,
			FechaDueno2: data?.accidentDate || null,
		},
		IndAutoGestion: true, // always true
		damagesPhotos: redactDataUrl(data.photos),
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		DepositoDirecto: {
			EsPropietario: true,
			EsAsegurado: true,
			NombreReclamante: data?.directDeposit?.claimantName || '',
			NumeroReclamacion: data?.directDeposit?.claimantNumber || '',
			NumeroPoliza: data?.directDeposit?.policy || '',
			Sucursal: data?.directDeposit?.branchOffice || '',
			NumeroRuta: data?.directDeposit?.routeNumber || '',
			TipoCuenta: data?.directDeposit?.accountType || '',
			PersonalContacto: data?.directDeposit?.personalContact || '',
			Telefono: data?.directDeposit?.phone || '',
			NombreInstitucionFinanciera: data?.directDeposit?.financialInstitution || '',
			FechaFirmaDeposito: formatDate || '',
			FirmaDeposito: data?.directDeposit?.signature || '',
		},
		AcceptTerms: 1,
		AcceptTermsSignature: data?.signature,
		AcceptTermsEmail: data?.injured?.fields?.email,
		finish,
	};
};

export const inspectionModelMultinational = (data: AppContext, finish = false): any => {
	const currentDate = new Date();
	const formatDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();

	const multinationalModel = {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		DriverName: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: (data?.insured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
		EventStory: data?.insured?.narration || '',
		AccidentDateOccurred: data?.accidentDate || null,
		DatosDelAccidente: {
			AM: data?.accidentInfo?.AM || '',
			Barrio: data?.accidentInfo?.Barrio || '',
			Carretera: data?.accidentInfo?.Carretera || '',
			Codificacion: data?.accidentInfo?.Codificacion || '',
			Fecha: data?.accidentInfo?.Fecha || '',
			Hora: data?.accidentInfo?.Hora || '',
			Kilometro: data?.accidentInfo?.Kilometro || '',
			Lugar: data?.accidentInfo?.Lugar || '',
			LugarDelAccidente: data?.location?.selectedLocation || '',
			Municipio: data?.accidentInfo?.Municipio || '',
			PM: data?.accidentInfo?.PM || '',
			Vehiculos: data?.accidentInfo?.Vehiculos || '',
			FirmaCroquis: data?.signature || '',
		},
		...(data.ClaimantType === 'Asegurado'
			? {
					//secured
					DatosVehiculo1: {
						DuenoNombre: data?.insured?.fields?.firstName || '',
						DuenoApellidos: data?.insured?.fields?.lastName || '',
						// Mjolnir 🔨
						DuenoSexo:
							data?.insured?.fields?.sex === '1'
								? '2'
								: data?.insured?.fields?.sex === '2'
								? '1'
								: data?.insured?.fields?.sex || '',
						DuenoLicencia: data?.insured?.fields?.license || '',
						DuenoDireccion1: data?.insured?.fields?.urbanization || '',
						DuenoCodigoPostal: data?.insured?.fields?.postalCode || '',
						DuenoTelCelular: data?.insured?.fields?.phone || '',
						DuenoTelTrabajo: data?.insured?.fields?.phone || '',
						DuenoEmail: data?.insured?.fields?.email || '',
						ConductorNombre: data?.insured?.fields?.driverName || '',
						ConductorApellidos: data?.insured?.fields?.driverLastName || '',
						ConductorSexo:
							data?.insured?.fields?.driverSex === '1'
								? '2'
								: data?.insured?.fields?.driverSex === '2'
								? '1'
								: data?.insured?.fields?.driverSex || '',
						ConductorLicencia: data?.insured?.fields?.driverLicense || '',
						ConductorDireccion1: data?.insured?.fields?.driverUrbanization || '',
						ConductorCodigoPostal: data?.insured?.fields?.driverPostalCode || '',
						ConductorTelTrabajo: data?.insured?.fields?.driverPhone,
						ConductorTelCelular: data?.insured?.fields?.driverPhone,
						ConductorVencimientoMarbete: data?.insured?.fields?.driverMarbete || '',
						Millaje: data?.insured?.fields?.driverMileage || '',
						ConductorEmail: data?.insured?.fields?.driverEmail,
						Marca: data?.insured?.fields?.make || '',
						Modelo: data?.insured?.fields?.model || '',
						Ano: data?.insured?.fields?.year || '',
						Color: data?.insured?.fields?.color || '',
						Tablilla: data?.insured?.fields?.plate || '',
						Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
						IsOtherDriver: data?.insured?.fields?.isOtherDriver,
						vin: data?.insured?.fields?.vin || '',
					},
					DatosVehiculo2: {
						Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
					},
			  }
			: {
					// injured
					DatosVehiculo2: {
						DuenoNombre: data?.injured?.fields?.firstName || '',
						DuenoApellidos: data?.injured?.fields?.lastName || '',
						// Mjolnir 🔨
						DuenoSexo:
							data?.injured?.fields?.sex === '1'
								? '2'
								: data?.injured?.fields?.sex === '2'
								? '1'
								: data?.injured?.fields?.sex || '',
						DuenoLicencia: data?.injured?.fields?.license || '',
						DuenoDireccion1: data?.injured?.fields?.urbanization || '',
						DuenoCodigoPostal: data?.injured?.fields?.postalCode || '',
						DuenoTelCelular: data?.injured?.fields?.phone || '',
						DuenoTelTrabajo: data?.injured?.fields?.phone || '',
						DuenoEmail: data?.injured?.fields?.email || '',
						ConductorNombre: data?.injured?.fields?.driverName || '',
						ConductorApellidos: data?.injured?.fields?.driverLastName || '',
						ConductorSexo:
							data?.injured?.fields?.driverSex === '1'
								? '2'
								: data?.injured?.fields?.driverSex === '2'
								? '1'
								: data?.injured?.fields?.driverSex || '',
						ConductorLicencia: data?.injured?.fields?.driverLicense || '',
						ConductorDireccion1: data?.injured?.fields?.driverUrbanization || '',
						ConductorCodigoPostal: data?.injured?.fields?.driverPostalCode || '',
						ConductorTelTrabajo: data?.injured?.fields?.driverPhone,
						ConductorTelCelular: data?.injured?.fields?.driverPhone,
						ConductorVencimientoMarbete: data?.injured?.fields?.driverMarbete || '',
						Millaje: data?.injured?.fields?.driverMileage || '',
						ConductorEmail: data?.injured?.fields?.driverEmail,
						Marca: data?.injured?.fields?.make || '',
						Modelo: data?.injured?.fields?.model || '',
						Ano: data?.injured?.fields?.year || '',
						Color: data?.injured?.fields?.color || '',
						Tablilla: data?.injured?.fields?.plate || '',
						Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
						IsOtherDriver: data?.injured?.fields?.isOtherDriver,
						vin: data?.injured?.fields?.vin || '',
					},
					DatosVehiculo1: null,
			  }),
		injured: [
			[...getDamagesForSICForm(data.injured.damages, data.inspectionId!)], // insured has to be first
		],
		injuredDamages: data.injured.damages,
		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),
		insuredDamages: data.insured.damages,
		counterpartDamages: data.ClaimantType === 'Asegurado' ? data.counterpart.damages : {},
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_PR',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress: data?.location?.address || '',
		EventAddress: data?.location?.address || '',
		location: data?.location || {},
		locations: {
			situation: {
				address: data?.location?.address || '',
				province: undefined, // validar si lo queremos enviar desde Helios
				canton: undefined, // validar si lo queremos enviar desde Helios
				district: undefined, // validar si lo queremos enviar desde Helios
			},
		},
		// responsibility
		partePoliciva: data?.responsibility?.responsible === Responsible.NONE ? 'si' : 'no',
		IndTicket: data?.responsibility?.responsible === Responsible.NONE,
		indFUD: data?.responsibility?.responsible === Responsible.NONE ? 'no' : 'si',
		IndFUD: data?.responsibility?.responsible !== Responsible.NONE,
		responsibility: data?.responsibility?.responsible,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		Firmas: {
			...(data.ClaimantType === 'Asegurado'
				? {
						FirmaDueno1: data?.signature,
						FechaDueno1: finish ? formatDate : null,
						FirmaDueno2: null,
						FechaDueno2: null,
				  }
				: {
						FirmaDueno1: null,
						FechaDueno1: null,
						FirmaDueno2: data?.signature,
						FechaDueno2: finish ? formatDate : null,
				  }),
		},
		IndAutoGestion: true, // always true
		damagesPhotos: redactDataUrl(data.photos),
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		DepositoDirecto: {
			EsPropietario: true,
			EsAsegurado: true,
			NombreReclamante: data?.directDeposit?.claimantName || '',
			NumeroReclamacion: data?.directDeposit?.claimantNumber || '',
			NumeroPoliza: data?.directDeposit?.policy || '',
			Sucursal: data?.directDeposit?.branchOffice || '',
			NumeroRuta: data?.directDeposit?.routeNumber || '',
			TipoCuenta: data?.directDeposit?.accountType || '',
			PersonalContacto: data?.directDeposit?.personalContact || '',
			Telefono: data?.directDeposit?.phone || '',
			NombreInstitucionFinanciera: data?.directDeposit?.financialInstitution || '',
			FechaFirmaDeposito: formatDate || '',
			FirmaDeposito: data?.directDeposit?.signature || '',
		},
		AcceptTerms: 1,
		AcceptTermsSignature: data?.signature,
		AcceptTermsEmail: data?.injured?.fields?.email,
		finish,
	};

	return multinationalModel;
};

export const inspectionModelPREMIER = (data: AppContext, finish = false): any => {
	const currentDate = new Date();
	const formatDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
	return {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		DriverName: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: (data?.insured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
		EventStory: data?.insured?.narration || '',
		AccidentDateOccurred: data?.accidentDate || null,
		DatosDelAccidente: {
			AM: data?.accidentInfo?.AM || '',
			Barrio: data?.accidentInfo?.Barrio || '',
			Carretera: data?.accidentInfo?.Carretera || '',
			Codificacion: data?.accidentInfo?.Codificacion || '',
			Fecha: data?.accidentInfo?.Fecha || '',
			Hora: data?.accidentInfo?.Hora || '',
			Kilometro: data?.accidentInfo?.Kilometro || '',
			Lugar: data?.accidentInfo?.Lugar || '',
			LugarDelAccidente: data?.location?.selectedLocation || '',
			Municipio: data?.accidentInfo?.Municipio || '',
			PM: data?.accidentInfo?.PM || '',
			Vehiculos: data?.accidentInfo?.Vehiculos || '',
			FirmaCroquis: data?.signature || '',
		},
		...(data.ClaimantType === 'Asegurado'
			? {
					//secured
					DatosVehiculo1: {
						DuenoNombre: data?.insured?.fields?.firstName || '',
						DuenoApellidos: data?.insured?.fields?.lastName || '',
						// Mjolnir 🔨
						DuenoSexo:
							data?.insured?.fields?.sex === '1'
								? '2'
								: data?.insured?.fields?.sex === '2'
								? '1'
								: data?.insured?.fields?.sex || '',
						DuenoLicencia: data?.insured?.fields?.license || '',
						DuenoDireccion1: data?.insured?.fields?.urbanization || '',
						DuenoCodigoPostal: data?.insured?.fields?.postalCode || '',
						DuenoTelCelular: data?.insured?.fields?.phone || '',
						DuenoTelTrabajo: data?.insured?.fields?.phone || '',
						DuenoEmail: data?.insured?.fields?.email || '',
						ConductorNombre: data?.insured?.fields?.driverName || '',
						ConductorApellidos: data?.insured?.fields?.driverLastName || '',
						ConductorSexo:
							data?.insured?.fields?.driverSex === '1'
								? '2'
								: data?.insured?.fields?.driverSex === '2'
								? '1'
								: data?.insured?.fields?.driverSex || '',
						ConductorLicencia: data?.insured?.fields?.driverLicense || '',
						ConductorDireccion1: data?.insured?.fields?.driverUrbanization || '',
						ConductorCodigoPostal: data?.insured?.fields?.driverPostalCode || '',
						ConductorTelTrabajo: data?.insured?.fields?.driverPhone,
						ConductorTelCelular: data?.insured?.fields?.driverPhone,
						ConductorVencimientoMarbete: data?.insured?.fields?.driverMarbete || '',
						Millaje: data?.insured?.fields?.driverMileage || '',
						ConductorEmail: data?.insured?.fields?.driverEmail,
						Marca: data?.insured?.fields?.make || '',
						Modelo: data?.insured?.fields?.model || '',
						Ano: data?.insured?.fields?.year || '',
						Color: data?.insured?.fields?.color || '',
						Tablilla: data?.insured?.fields?.plate || '',
						Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
						IsOtherDriver: data?.insured?.fields?.isOtherDriver,
						vin: data?.insured?.fields?.vin || '',
					},
					DatosVehiculo2: {
						Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
					},
			  }
			: {
					// injured
					DatosVehiculo2: {
						DuenoNombre: data?.injured?.fields?.firstName || '',
						DuenoApellidos: data?.injured?.fields?.lastName || '',
						// Mjolnir 🔨
						DuenoSexo:
							data?.injured?.fields?.sex === '1'
								? '2'
								: data?.injured?.fields?.sex === '2'
								? '1'
								: data?.injured?.fields?.sex || '',
						DuenoLicencia: data?.injured?.fields?.license || '',
						DuenoDireccion1: data?.injured?.fields?.urbanization || '',
						DuenoCodigoPostal: data?.injured?.fields?.postalCode || '',
						DuenoTelCelular: data?.injured?.fields?.phone || '',
						DuenoTelTrabajo: data?.injured?.fields?.phone || '',
						DuenoEmail: data?.injured?.fields?.email || '',
						ConductorNombre: data?.injured?.fields?.driverName || '',
						ConductorApellidos: data?.injured?.fields?.driverLastName || '',
						ConductorSexo:
							data?.injured?.fields?.driverSex === '1'
								? '2'
								: data?.injured?.fields?.driverSex === '2'
								? '1'
								: data?.injured?.fields?.driverSex || '',
						ConductorLicencia: data?.injured?.fields?.driverLicense || '',
						ConductorDireccion1: data?.injured?.fields?.driverUrbanization || '',
						ConductorCodigoPostal: data?.injured?.fields?.driverPostalCode || '',
						ConductorTelTrabajo: data?.injured?.fields?.driverPhone,
						ConductorTelCelular: data?.injured?.fields?.driverPhone,
						ConductorVencimientoMarbete: data?.injured?.fields?.driverMarbete || '',
						Millaje: data?.injured?.fields?.driverMileage || '',
						ConductorEmail: data?.injured?.fields?.driverEmail,
						Marca: data?.injured?.fields?.make || '',
						Modelo: data?.injured?.fields?.model || '',
						Ano: data?.injured?.fields?.year || '',
						Color: data?.injured?.fields?.color || '',
						Tablilla: data?.injured?.fields?.plate || '',
						Ocurrido: (data?.injured?.narration || '') + ' // ' + getCounterpartDamagesForSIC(data.injured.damages),
						IsOtherDriver: data?.injured?.fields?.isOtherDriver,
						vin: data?.injured?.fields?.vin || '',
					},
					DatosVehiculo1: null,
			  }),
		injured: [
			[...getDamagesForSICForm(data.injured.damages, data.inspectionId!)], // insured has to be first
		],
		injuredDamages: data.injured.damages,
		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),
		insuredDamages: data.insured.damages,
		counterpartDamages: data.ClaimantType === 'Asegurado' ? data.counterpart.damages : {},
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_PR',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress: data?.location?.address || '',
		EventAddress: data?.location?.address || '',
		location: data?.location || {},
		locations: {
			situation: {
				address: data?.location?.address || '',
				province: undefined, // validar si lo queremos enviar desde Helios
				canton: undefined, // validar si lo queremos enviar desde Helios
				district: undefined, // validar si lo queremos enviar desde Helios
			},
		},
		// responsibility
		partePoliciva: data?.responsibility?.responsible === Responsible.NONE ? 'si' : 'no',
		IndTicket: data?.responsibility?.responsible === Responsible.NONE,
		indFUD: data?.responsibility?.responsible === Responsible.NONE ? 'no' : 'si',
		IndFUD: data?.responsibility?.responsible !== Responsible.NONE,
		responsibility: data?.responsibility?.responsible,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		Firmas: {
			...(data.ClaimantType === 'Asegurado'
				? {
						FirmaDueno1: data?.signature,
						FechaDueno1: finish ? formatDate : null,
						FirmaDueno2: null,
						FechaDueno2: null,
				  }
				: {
						FirmaDueno1: null,
						FechaDueno1: null,
						FirmaDueno2: data?.signature,
						FechaDueno2: finish ? formatDate : null,
				  }),
		},
		IndAutoGestion: true, // always true
		damagesPhotos: redactDataUrl(data.photos),
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		DepositoDirecto: {
			EsPropietario: true,
			EsAsegurado: true,
			NombreReclamante: data?.directDeposit?.claimantName || '',
			NumeroReclamacion: data?.directDeposit?.claimantNumber || '',
			NumeroPoliza: data?.directDeposit?.policy || '',
			Sucursal: data?.directDeposit?.branchOffice || '',
			NumeroRuta: data?.directDeposit?.routeNumber || '',
			TipoCuenta: data?.directDeposit?.accountType || '',
			PersonalContacto: data?.directDeposit?.personalContact || '',
			Telefono: data?.directDeposit?.phone || '',
			NombreInstitucionFinanciera: data?.directDeposit?.financialInstitution || '',
			FechaFirmaDeposito: formatDate || '',
			FirmaDeposito: data?.directDeposit?.signature || '',
		},
		AcceptTerms: 1,
		AcceptTermsSignature: data?.signature,
		AcceptTermsEmail: data?.injured?.fields?.email,
		finish,
	};
};

export const inspectionModelLafiseAndAssa = (data: AppContext, finish = false): any => {
	let laaModel = {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: data?.insured?.narration || '',
		narrationAdditional: data?.insured?.narrationAdditional || '',
		EventStory: data?.insured?.narration || '',
		ReportStory: data?.insured?.narration || '',
		AccidentDateOccurred: `${data?.location?.date} ${data?.location.time}`,
		RazonAvisoInmediato: data?.insured?.alertNarration || '',
		//Conductor
		DriverName: data?.insured?.fields?.driverName || '',
		DriverLastNames: data?.insured?.fields?.driverLastName || '',
		DriverEmail: data?.insured?.fields?.driverEmail || '',
		DriverRelationship: data?.insured?.fields?.driverRelationship || '',
		DriverLicense: data?.insured?.fields?.driverLicense || '',
		DriverPhone: data?.insured?.fields?.driverPhone || '',
		DriverMovil: data?.insured?.fields?.driverPhone || '',
		DriverSex: data?.insured?.fields?.driverSex || '',
		DatosVehiculo1: {
			DuenoNombre: data?.insured?.fields?.firstName || '',
			DuenoApellidos: data?.insured?.fields?.lastName || '',
			DuenoSexo: data?.insured?.fields?.sex || '',
			DuenoLicencia: data?.insured?.fields?.license || '',
		},
		//counterpart
		colorC1: data?.counterpart?.fields?.color || '',
		emailC1: data?.counterpart?.fields?.email || '',
		marcaC1: data?.counterpart?.fields?.make || '',
		modeloC1: data?.counterpart?.fields?.model || '',
		anoC1: data?.counterpart?.fields?.year || '',
		placaC1: data?.counterpart?.fields?.plate || '',
		propietarioC1: data?.counterpart?.fields?.name || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_1: data?.counterpart?.fields?.phone || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_2: data?.counterpart?.fields?.phone || '',
		thirdTypeC1: 'Driver',

		Third: getThirdData(data),
		ThirdAdditional: getThirdData(data),
		hasThird: data.selectedIsVehicle && data.typeIsVehicle === 'isVehicle',

		// damages para sic form
		injured: [
			[...getDamagesForSICForm(data.insured.damages, data.inspectionId!)], // insured has to be first
			[...getDamagesForSICForm(data.counterpart.damages, data.inspectionId!)], // counterpart has to be second
		],

		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),

		insuredDamages: data.insured.damages,
		counterpartDamages: data.counterpart.damages,
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_CR',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress:
			data?.location?.isManualLocation === true ? data?.accidentInfo?.Lugar : data?.location?.address || '',
		EventAddress:
			data?.location?.isManualLocation === true
				? data?.accidentInfo?.Lugar
				: (data?.location?.selectedLocation === 'default'
						? data?.location?.address
						: data?.location?.originalAddress) || '',
		location: data?.location || {},
		// responsibility
		partePoliciva: data?.policivePart ? 'si' : 'no',
		IndTicket: data?.policivePart || false,
		indFUD: 'no',
		IndFUD: false,
		responsibility: data?.responsibility?.responsible,
		TrafficPolicePresented: data?.policivePart,
		EventTrafficTicket: data?.numberPart,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		IndAutoGestion: true, // always true
		damagesPhotos: redactDataUrl(data.photos),
		galleryPhotos: data.galleryPhotos,
		InspeccionLugarAccidente:
			data?.location?.selectedLocation !== null ? data?.location?.selectedLocation === 'default' : null,
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		finish,
	};

	if (
		data?.location?.level1.length > 0 &&
		data?.location?.level2.length > 0 &&
		data?.location?.level3.length > 0
		//data?.location?.isManualLocation === true
	) {
		if (data?.location?.selectedLocation === 'default') {
			const newObj = {
				...laaModel,
				AccidentAddress: data?.accidentInfo?.Lugar ?? data?.location?.originalAddress,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.location?.originalAddress,
				EventProvinceId: data?.location?.level1,
				EventCantonId: data?.location?.level2,
				EventDistrictId: data?.location?.level3,
				locations: {
					situation: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			laaModel = newObj;
		} else {
			const newObj = {
				...laaModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.location?.originalAddress || '',
				EventProvinceId: data?.location?.customLevel1,
				EventCantonId: data?.location?.customLevel2,
				EventDistrictId: data?.location?.customLevel3,
				locations: {
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			laaModel = newObj;
		}
	}

	return laaModel;
};

export const inspectionModelOceanica = (data: AppContext, finish = false): any => {
	let oceanicaModel = {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: data?.insured?.narration || '',
		narrationAdditional: data?.insured?.narrationAdditional || '',
		EventStory: data?.insured?.narration || '',
		ReportStory: data?.insured?.narration || '',
		AccidentDateOccurred: `${data?.location?.date} ${data?.location.time}`,
		RazonAvisoInmediato: data?.insured?.alertNarration || '',
		//Conductor
		DriverName: data?.insured?.fields?.driverName || '',
		DriverLastNames: data?.insured?.fields?.driverLastName || '',
		DriverEmail: data?.insured?.fields?.driverEmail || '',
		DriverRelationship: data?.insured?.fields?.driverRelationship || '',
		DriverLicense: data?.insured?.fields?.driverLicense || '',
		DriverPhone: data?.insured?.fields?.driverPhone || '',
		DriverMovil: data?.insured?.fields?.driverPhone || '',
		DriverSex: data?.insured?.fields?.driverSex || '',
		DatosVehiculo1: {
			DuenoNombre: data?.insured?.fields?.firstName || '',
			DuenoApellidos: data?.insured?.fields?.lastName || '',
			DuenoSexo: data?.insured?.fields?.sex || '',
			DuenoLicencia: data?.insured?.fields?.license || '',
		},
		//counterpart
		colorC1: data?.counterpart?.fields?.color || '',
		emailC1: data?.counterpart?.fields?.email || '',
		marcaC1: data?.counterpart?.fields?.make || '',
		modeloC1: data?.counterpart?.fields?.model || '',
		anoC1: data?.counterpart?.fields?.year || '',
		placaC1: data?.counterpart?.fields?.plate || '',
		propietarioC1: data?.counterpart?.fields?.name || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_1: data?.counterpart?.fields?.phone || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_2: data?.counterpart?.fields?.phone || '',
		thirdTypeC1: 'Driver',

		Third: getThirdData(data),
		ThirdAdditional: getThirdData(data),
		hasThird: data.selectedIsVehicle && data.typeIsVehicle === 'isVehicle',

		// damages para sic form
		injured: [
			[...getDamagesForSICForm(data.insured.damages, data.inspectionId!)], // insured has to be first
			[...getDamagesForSICForm(data.counterpart.damages, data.inspectionId!)], // counterpart has to be second
		],

		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),

		insuredDamages: data.insured.damages,
		counterpartDamages: data.counterpart.damages,
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_CR',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress:
			data?.location?.isManualLocation === true ? data?.accidentInfo?.Lugar : data?.location?.address || '',
		EventAddress:
			data?.location?.isManualLocation === true
				? data?.accidentInfo?.Lugar
				: (data?.location?.selectedLocation === 'default'
						? data?.location?.address
						: data?.location?.originalAddress) || '',
		location: data?.location || {},
		// responsibility
		partePoliciva: data?.policivePart ? 'si' : 'no',
		IndTicket: data?.policivePart || false,
		indFUD: 'no',
		IndFUD: false,
		responsibility: data?.responsibility?.responsible,
		TrafficPolicePresented: data?.policivePart,
		EventTrafficTicket: data?.numberPart,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		IndAutoGestion: true, // always true
		damagesPhotos: data.photos,
		galleryPhotos: data.galleryPhotos,
		InspeccionLugarAccidente:
			data?.location?.selectedLocation !== null ? data?.location?.selectedLocation === 'default' : null,
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		finish,
	};

	if (
		data?.location?.level1.length > 0 &&
		data?.location?.level2.length > 0 &&
		data?.location?.level3.length > 0 //&&
		//data?.location?.isManualLocation === true
	) {
		if (data?.location?.selectedLocation === 'default') {
			const newObj = {
				...oceanicaModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.accidentInfo?.Lugar,
				EventProvinceId: data?.location?.level1,
				EventCantonId: data?.location?.level2,
				EventDistrictId: data?.location?.level3,
				locations: {
					situation: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			oceanicaModel = newObj;
		} else {
			const newObj = {
				...oceanicaModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.location?.originalAddress || '',
				EventProvinceId: data?.location?.customLevel1,
				EventCantonId: data?.location?.customLevel2,
				EventDistrictId: data?.location?.customLevel3,
				locations: {
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			oceanicaModel = newObj;
		}
	}

	return oceanicaModel;
};

export const inspectionModelQualitas = (data: AppContext, finish = false): any => {
	let qualitasModel = {
		id: data.inspectionId,
		//insured
		color: data?.insured?.fields?.color || '',
		OwnerVehicleColor: data?.insured?.fields?.color || '',
		emailConductor: data?.insured?.fields?.email || '',
		OwnerEmail: data?.insured?.fields?.email || '',
		marca: data?.insured?.fields?.make || '',
		OwnerVehicleBrand: data?.insured?.fields?.make || '',
		modelo: data?.insured?.fields?.model || '',
		OwnerVehicleModel: data?.insured?.fields?.model || '',
		ano: data?.insured?.fields?.year || '',
		OwnerVehicleYear: data?.insured?.fields?.year || '',
		nombreConductor: data?.insured?.fields?.firstName || '',
		apellidosConductor: data?.insured?.fields?.lastName || '',
		OwnerLastNames: data?.insured?.fields?.lastName || '', // actualizar a last name // actually es el driver lastname
		placa: data?.insured?.fields?.plate || '',
		VehicleLisencePlate: data?.insured?.fields?.plate || '', // has a typo in database
		narracionHechos: data?.insured?.narration || '',
		narrationAdditional: data?.insured?.narrationAdditional || '',
		EventStory: data?.insured?.narration || '',
		ReportStory: data?.insured?.narration || '',
		AccidentDateOccurred: `${data?.location?.date} ${data?.location.time}`,
		RazonAvisoInmediato: data?.insured?.alertNarration || '',
		//Conductor
		DriverName: data?.insured?.fields?.driverName || '',
		DriverLastNames: data?.insured?.fields?.driverLastName || '',
		DriverEmail: data?.insured?.fields?.driverEmail || '',
		DriverRelationship: data?.insured?.fields?.driverRelationship || '',
		DriverLicense: data?.insured?.fields?.driverLicense || '',
		DriverPhone: data?.insured?.fields?.driverPhone || '',
		DriverMovil: data?.insured?.fields?.driverPhone || '',
		DriverSex: data?.insured?.fields?.driverSex || '',
		DatosVehiculo1: {
			DuenoNombre: data?.insured?.fields?.firstName || '',
			DuenoApellidos: data?.insured?.fields?.lastName || '',
			DuenoSexo: data?.insured?.fields?.sex || '',
			DuenoLicencia: data?.insured?.fields?.license || '',
		},
		//counterpart
		colorC1: data?.counterpart?.fields?.color || '',
		emailC1: data?.counterpart?.fields?.email || '',
		marcaC1: data?.counterpart?.fields?.make || '',
		modeloC1: data?.counterpart?.fields?.model || '',
		anoC1: data?.counterpart?.fields?.year || '',
		placaC1: data?.counterpart?.fields?.plate || '',
		propietarioC1: data?.counterpart?.fields?.name || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_1: data?.counterpart?.fields?.phone || '',
		// eslint-disable-next-line @typescript-eslint/camelcase
		telefonoC1_2: data?.counterpart?.fields?.phone || '',
		thirdTypeC1: 'Driver',

		Third: getThirdData(data),
		ThirdAdditional: getThirdData(data),
		hasThird: data.selectedIsVehicle && data.typeIsVehicle === 'isVehicle',

		// damages para sic form
		injured: [
			[...getDamagesForSICForm(data.insured.damages, data.inspectionId!)], // insured has to be first
			[...getDamagesForSICForm(data.counterpart.damages, data.inspectionId!)], // counterpart has to be second
		],

		// damages para connectservices
		Injured: getInsuredDamagesForSIC(data.insured.damages),

		insuredDamages: data.insured.damages,
		counterpartDamages: data.counterpart.damages,
		// photos
		LinkPhotos: [
			...data?.photos
				.filter((p) => p.awsUrl)
				.map((p) => ({
					Description: p.description,
					ImageSectionId: p.section,
					ImageUrl: p.awsUrl,
					ImageSource: 'SIC_CR',
				})),
		],
		//location
		Latitud: data?.location?.coords?.lat,
		Longitud: data?.location?.coords?.lng,
		Coordinates: `${data?.location?.coords?.lat},${data?.location?.coords?.lng}`,
		lugar: data?.location?.address || '',
		AccidentAddress:
			data?.location?.isManualLocation === true ? data?.accidentInfo?.Lugar : data?.location?.address || '',
		EventAddress:
			data?.location?.isManualLocation === true
				? data?.accidentInfo?.Lugar
				: (data?.location?.selectedLocation === 'default'
						? data?.location?.address
						: data?.location?.originalAddress) || '',
		location: data?.location || {},
		// responsibility
		partePoliciva: data?.policivePart ? 'si' : 'no',
		IndTicket: data?.policivePart || false,
		indFUD: 'no',
		IndFUD: false,
		responsibility: data?.responsibility?.responsible,
		TrafficPolicePresented: data?.policivePart,
		EventTrafficTicket: data?.numberPart,
		draw4: data?.responsibility?.insuredInitials, // esto es una firma dataUrl
		asistenciaLegal: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		IndLegalAssistance: data?.responsibility?.legal, // en el form está oculto pedido por SURA
		cantidadContraparte: 1,
		Croquis: data?.sketch?.dataUrl,
		Scene: data?.sketch?.sceneType,
		Signatures: data?.signature,
		IndAutoGestion: true, // always true
		damagesPhotos: data.photos,
		galleryPhotos: data.galleryPhotos,
		InspeccionLugarAccidente:
			data?.location?.selectedLocation !== null ? data?.location?.selectedLocation === 'default' : null,
		Estado: finish ? -1 : undefined, // -1 means saved from sic form
		accountId: data?.accountId,
		incidentType: data?.incidentType,
		finish,
	};

	if (
		data?.location?.level1.length > 0 &&
		data?.location?.level2.length > 0 &&
		data?.location?.level3.length > 0 //&&
		//data?.location?.isManualLocation === true
	) {
		if (data?.location?.selectedLocation === 'default') {
			const newObj = {
				...qualitasModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.accidentInfo?.Lugar,
				EventProvinceId: data?.location?.level1,
				EventCantonId: data?.location?.level2,
				EventDistrictId: data?.location?.level3,
				locations: {
					situation: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			qualitasModel = newObj;
		} else {
			const newObj = {
				...qualitasModel,
				AccidentAddress: data?.accidentInfo?.Lugar,
				AccidentProvinceId: data?.location?.level1,
				AccidentCantonId: data?.location?.level2,
				AccidentDistrictId: data?.location?.level3,
				EventAddress: data?.location?.originalAddress || '',
				EventProvinceId: data?.location?.customLevel1,
				EventCantonId: data?.location?.customLevel2,
				EventDistrictId: data?.location?.customLevel3,
				locations: {
					insured: {
						address: data?.accidentInfo?.Lugar || '',
						province: data?.location?.level1 || '',
						canton: data?.location?.level2 || '',
						district: data?.location?.level3 || '',
					},
				},
			};
			qualitasModel = newObj;
		}
	}

	return qualitasModel;
};

export const resetDate = (date: Date) => {
	const auxDate = new Date(date);
	auxDate.setHours(0);
	auxDate.setMinutes(0);
	auxDate.setSeconds(0);
	auxDate.setMilliseconds(0);
	return auxDate;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const validateStr = (value?: string) => {
	if (value && value.length > 0) {
		return value;
	}
	return undefined;
};

export const getCodeLocations = async (
	levelDescrip1: string,
	levelDescrip2: string,
	levelDescrip3: string
): Promise<{ level1: string; level2: string; level3: string }> => {
	let defaultLevel1 = levelDescrip1;
	let defaultLevel2 = levelDescrip2;
	let defaultLevel3 = levelDescrip3;

	const dataToFilter = (await getLocationLevel1()).data;
	defaultLevel1 =
		Array.from(dataToFilter).filter(
			(f: any) =>
				String(f.name).length > 0 &&
				removeAccents(String(f.name).toUpperCase()).indexOf(removeAccents(String(defaultLevel1).toUpperCase())) > -1
		)[0]?.id || '';

	if (String(defaultLevel1).length === 0) {
		defaultLevel1 =
			Array.from(dataToFilter).filter(
				(f: any) =>
					String(f.name).length > 0 &&
					removeAccents(String(levelDescrip1).toUpperCase()).indexOf(removeAccents(String(f.name).toUpperCase())) > -1
			)[0]?.id || Array.from(dataToFilter)[0]?.id;
	}

	if (String(defaultLevel1).length > 0) {
		const dataToFilter = (await getLocationLevel2(defaultLevel1)).data;
		defaultLevel2 = removeAccents(String(defaultLevel2).toUpperCase()) === 'SAN JOSE' ? 'CENTRAL' : defaultLevel2;
		defaultLevel2 =
			Array.from(dataToFilter).filter(
				(f: any) =>
					String(f.name).length > 0 &&
					removeAccents(String(f.name).toUpperCase()).indexOf(removeAccents(String(defaultLevel2).toUpperCase())) > -1
			)[0]?.id || Array.from(dataToFilter)[0]?.id;
	}

	if (String(defaultLevel2).length > 0) {
		const dataToFilter = (await getLocationLevel3(defaultLevel1, defaultLevel2)).data;
		defaultLevel3 =
			Array.from(dataToFilter).filter(
				(f: any) =>
					String(f.name).length > 0 &&
					removeAccents(String(f.name).toUpperCase()).indexOf(removeAccents(String(defaultLevel3).toUpperCase())) > -1
			)[0]?.id || Array.from(dataToFilter)[0]?.id;
	}

	return {
		level1: defaultLevel1,
		level2: defaultLevel2,
		level3: defaultLevel3,
	};
};

export const removeAccents = (str: string): string => {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
