import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext, DispatchFunction, Brands } from '../../context/interfaces';
import { logo, getCurrentLocation, expressService, headerLogo } from '../../context/Utils';
import { userSessionGTM } from 'shared/utils/gooogleTagManager';
import { BRAND } from 'shared/utils';
import '../../assets/styles/screens/splash/Splash.scss';
import PATH_ROUTES from '../../static/constants/path-routes';
import useDevice from '../../hooks/useDevice';

const TIME_TO_REDIRECT = 4000;

// TODO: Needs security.
export const fetchEventData = async (id: string, dispatch: DispatchFunction, context: AppContext): Promise<void> => {
	const data = await new HeliosClient().getInspection(id, context);
	localStorage.setItem('ClaimantType', data.ClaimantType);
	dispatch({ type: ActionType.SET_EVENT, data });
};

const SplashScreen = (): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const history = useHistory();
	const { dispatch } = context;
	const { id } = useParams<{ id: string }>();
	const resultEnv = useDevice();

	const getEvent = (): void => {
		(async (): Promise<void> => {
			if (resultEnv.isDesktop) {
				history.push(PATH_ROUTES.PRE_ALERT_DEVICE_SCREEN);
			} else {
				if (id) {
					// Clear all local data when opening the event. Prevents rewriting data with information of other events
					localStorage.clear();
					localStorage.setItem('inspectionId', id);

					getCurrentLocation();
					setTimeout(async () => {
						await fetchEventData(id, dispatch, context);
					}, 3000);
				}
				setTimeout(() => {
					// Send Init Session to GT
					userSessionGTM(context);

					// TODO: Preferably we make sure data arrived before redirecting.
					BRAND === Brands.CSM || BRAND === Brands.MULTINATIONAL || BRAND === Brands.PREMIER
						? history.push('/' + id + '/injured/information')
						: history.push('/' + id + '/insured/information');
				}, TIME_TO_REDIRECT);
			}
		})();
	};
	useEffect(getEvent, []);

	return (
		<div className="splash">
			{BRAND === Brands.CSM ? (
				<>
					<div className="header-logo">
						<img src={headerLogo} alt="header logo" />
					</div>
					<div className="main-info">
						<img className="logo" src={expressService} alt="CSM Servicio Express" />
					</div>
				</>
			) : (
				<>
					<div className="main-info">
						<img src={logo} alt="" className="logo" />
						<h3>Bienvenido a tu Inspector Virtual</h3>
					</div>
					{[
						Brands.LAFISE,
						Brands.OCEANICA,
						Brands.QUALITAS,
						Brands.SEGUROS_MUNDIAL,
						Brands.MULTINATIONAL,
						Brands.PREMIER,
						Brands.COMFENALCO,
					].includes(BRAND as Brands) && (
						<p>
							Powered by <b>Connect Assistance</b>
						</p>
					)}
				</>
			)}
		</div>
	);
};

export default SplashScreen;
